<template>
  <div class="container-fluid h-auto">
    <div class="row">
      <div class="col-12">
        <TitleHeader>
          {{ getApplicationFullName }} Application Details
          <span class="logo-wrap" @click="handleHubspot">
            <HubspotLogo v-if="getApplicationHubspotId"/>
          </span>
        </TitleHeader>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CopyText :value="getApplicationUuid" class="mb-3" title="UUID:&nbsp;"/>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-6">
        <div class="card h-100">
          <div class="card-body text-left">
            <div class="d-flex ">
              <h5 class="card-title pr-2">Details</h5>
              <Siganture/>
            </div>

            <ApplicationForm/>
            <div class="row">
              <div class="col-6">
                <div class="row text-left">
                  <div class="col-6">
                    <p>
                      <strong>Application Password:</strong><br/>
                      {{
                        getApplication?.password || "Password not generated yet"
                      }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p>
                      <strong>Date of birth:</strong><br/>
                      {{ formatDate(getApplication?.dob) }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p>
                      <strong>NI Number:</strong><br/>
                      {{ getApplication?.ni_number }}
                    </p>
                  </div>
                  <div v-if="getPreviousNamesLength > 0" class="col-6">
                    <InfoModal ref="previousName">
                      <template #btn-text>
                        view previous names
                      </template>
                      <template #title>
                        Previous Names
                      </template>
                      <template #content>
                        <div v-for='(name, index) in getPreviousNames' :key='index'>
                          <span>{{ name.previous_first_name }}&nbsp;{{ name.previous_last_name }}</span>
                        </div>
                      </template>
                    </InfoModal>
                  </div>
                  <div class="col-6">
                    <p class="margin-top: 1rem">
                      <strong>Created:</strong><br/>
                      {{
                        formatReadDateTime(
                            getApplication?.created_at
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p>
                      <strong>Assigned to:</strong><br/>
                      {{ getOwner }}
                      &nbsp;&nbsp;
                      <UnassignUser
                          v-if="getOwner !== 'Unassigned'"
                          :application="getApplication"
                          :user-id="getAssignedUserId"
                          class="pointer_all"
                          @application-unassigned="handleUnassigned"
                      />
                    </p>
                  </div>
                  <div class="col-6">
                    <StatusUpdate
                        ref="statusUpdateComponent"
                        :reason-required="false"
                        type="app"
                        @status-changed="handleStatus"/>

                    <div v-if="getApplication?.application_state?.length > 1">
                      <hr>
                      <StatusHistory
                          :state-current="getApplication.current_application_state"
                          :state-previous="getApplication.previous_application_state"
                          :state-list="getApplication.application_state"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <TestApplication
                        ref="testApplicationComponent"
                        type="app"
                        @test-application-changed="updateTestApplication"/>
                  </div>


                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <p class="margin-top: 1rem">
                      <strong>Source:</strong><br/>
                      {{ (getApplication?.source)?.toUpperCase() }}<br/>
                      {{ (getApplication?.receiving_platform)?.toUpperCase() }}
                    </p>
                  </div>
                  <div class="col-12 mb-5">
                    <AddressForm ref="addressForm"/>
                    <div class="font-weight-bold">Address:
                      <button class="edit-btn" name="button" type="button" @click="openEditAddressDetails">
                        edit address details
                      </button>
                    </div>
                    <div v-html="getAddress"></div>
                    <InfoModal v-if="getPreviousAddressLength > 0" ref="previousAddress">
                      <template #btn-text>
                        view previous address
                      </template>
                      <template #title>
                        Previous Address
                      </template>
                      <template #content>
                        <div v-for="(address, index) in getPreviousAddress" :key="index"
                             class="previous-address" v-html="formatAddressHTML(address, true)"></div>
                      </template>
                    </InfoModal>


                  </div>
                  <div class="col-12">
                    <ContactForm ref="contactForm"/>
                    <div class="font-weight-bold">Contact:
                      <button class="edit-btn" name="button" type="button" @click="openEditContactDetails">
                        edit contact details
                      </button>
                    </div>
                    <div> {{ getContact }}</div>
                    <div>
                      <a :href="getEmail">{{ getApplication?.email }}</a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <EmailCorrespondance parent="app"/>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <h5 class="card-title">Traced Pensions</h5>

            <InfoModal ref="addPensionTrace" class="mb-3">
              <template #btn-text>
                add a pension trace
              </template>
              <template #content>
                <AddPensionTrace :add-pension-form="true"/>
              </template>
            </InfoModal>

            <TracedPensionList :show-known-pension-switch="true" @handle-pension="handlePension"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import AddressForm from "@/components/form/AddressForm";
import ContactForm from "@/components/form/ContactForm";
import ApplicationForm from "@/components/form/ApplicationForm";
import TracedPensionList from "@/components/lists/TracedPensionList";
import Siganture from "@/components/application-funnel/Siganture";
import CopyText from "@/components/misc/CopyText";
import arrowRight from "@fa/solid/arrow-right.svg";
import {useDateFormat} from "@/logic/useDateFormat";
import AddPensionTrace from "@/components/application-funnel/PensionDetails";
import HubspotLogo from "@/components/logos/HubspotLogo";
import StatusUpdate from "@/components/form/StatusUpdate";
import EmailCorrespondance from "@/components/email-manager/EmailCorrespondance";
import TestApplication from "@/components/form/TestApplication";
import UnassignUser from "@/components/application-manager/UnassignUser";
import StatusHistory from "@/components/lists/StatusHistory.vue";

export default {
  name: "ApplicationsFocus",
  components: {
    StatusHistory,
    CopyText,
    AddressForm,
    ContactForm,
    ApplicationForm,
    Siganture,
    AddPensionTrace,
    HubspotLogo,
    StatusUpdate,
    EmailCorrespondance,
    TracedPensionList,
    TestApplication,
    UnassignUser
  },
  data: () => ({
    application: {},
    arrowRight,
    files: null,
  }),
  setup() {
    const {formatDate} = useDateFormat();
    return {
      formatDate
    };
  },
  computed: {
    ...mapGetters([
      "getApplication",
      "getStatusAppValues",
      "getApplicationFullName",
      "getCurrentAddress",
      "getUser"
    ]),
    getApplicationTest() {
      return this.application?.test;
    },
    getAssignedUserId() {
      return this.getApplication?.assigned_user[0]?.id;
    },
    getOwner() {
      if (
          typeof this.getApplication?.assigned_user == "undefined" ||
          this.getApplication?.assigned_user?.length < 1
      ) {
        return "Unassigned";
      } else {
        let owner = this.getApplication?.assigned_user[
        this.getApplication?.assigned_user?.length - 1
            ];
        return owner.first_name + " " + owner.last_name;
      }
    },
    getApplicationHubspotId() {
      return this.getApplication?.hubspot_customer_vid;
    },
    getApplicationUuid() {
      return this.getApplication?.uuid;
    },
    getAddress() {
      if (!this.getApplication?.address?.[0]?.address_1) {
        return "No address has been set";
      }

      return this.formatAddressHTML(this.getCurrentAddress);
    },
    getPreviousAddress() {
      let prevAddressArray = this.getApplication?.address?.filter(
          a => a.current !== "1"
      );
      return prevAddressArray;
    },
    getPreviousAddressLength() {
      if (!this.getPreviousAddress) return;
      return this.getPreviousAddress.length;
    },
    getPreviousNames() {
      let prevNamesArray = this.getApplication?.previous_names;
      return prevNamesArray;
    },
    getPreviousNamesLength() {
      return this.getApplication?.previous_names?.length;
    },
    getContact() {
      if (
          !this.getApplication?.contact ||
          !this.getApplication?.contact[this.getApplication?.contact.length - 1]
              ?.number
      ) {
        return "No contact has been set";
      }
      return (
          this.getApplication?.contact[this.getApplication?.contact.length - 1]
              ?.region +
          " " +
          this.getApplication?.contact[this.getApplication?.contact.length - 1]
              ?.number
      );
    },
    getEmail() {
      if (!this.getApplication?.email) {
        return "No email address has been set";
      }
      return "mailto:" + this.getApplication?.email;
    },
    getStatus() {
      let status = null;
      switch (this.getApplication?.status) {
        case "in-progress":
          status = "in progress";
          break;
      }
      return status;
    },
    getTracedPension() {
      return this.getApplication?.traced_pension;
    },
    getTasks() {
      const task = this.getTracedPension?.task;
      return task;
    }
  },
  methods: {
    ...mapMutations(["setLoading", "setApplication"]),
    ...mapActions([
      "getApplicationsFocus",
      "triggerAction",
      "updateApplication",
      "getDocuments",
      "updateApplicationTest",
    ]),
    handleUnassigned() {
      console.log('application unassigned');
    },
    async handleStatus(param) {
      let {status, reason} = param;
      await this.updateApplication({status, reason}).catch(err =>
          console.log(err)
      );
      this.$refs.statusUpdateComponent.edit = false;
      this.$refs.statusUpdateComponent.updating = false;
    },
    async updateTestApplication(param) {
      let {test} = param;
      await this.updateApplicationTest({test}).catch(err =>
          console.log(err)
      );

      this.$refs.testApplicationComponent.edit = false;
      this.$refs.testApplicationComponent.updating = false;
    },
    handleHubspot() {
      window.open(
          `${process.env.VUE_APP_HUBSPOT_URL}/contact/${this.getApplication.hubspot_customer_vid}/`
      );
    },
    handleEmployment() {
    },
    handlePension({tpuuid}) {
      this.$router.push({
        name: "Traced Pension",
        params: {
          uuid: this.getApplicationUuid,
          tpuuid
        }
      });
    },
    async handleAction({tp_id, type}) {
      if (!confirm("Action this task?")) return;
      this.setLoading({
        bool: true,
        message: "Acting..."
      });

      let response = await this.triggerAction({
        uuid: this.getApplication?.uuid,
        task_id: tp_id,
        type,
        model: "application"
      });

      this.setApplication(response.data.data);

      this.setLoading({bool: false});
    },
    openEditAddressDetails() {
      this.$refs.addressForm.$refs.editAddressForm.show = true
    },
    openEditContactDetails() {
      this.$refs.contactForm.$refs.editContactForm.show = true
    },

  },
  async beforeMount() {
    this.setLoading({
      bool: true
    });
    if (typeof this.$route.params == "undefined") return;
    await this.getApplicationsFocus(this.$route.params.uuid).catch(err =>
        console.log(err)
    );

    this.setLoading({
      bool: false
    });
  }
};
</script>
<style lang="scss" scoped>
.table-row {
  cursor: pointer;
}

.logo-wrap {
  display: inline-block;
  width: 40px;
  cursor: pointer;
}

.previous-address {
  padding-bottom: $spacingBase;
  margin-bottom: $spacingBase;
  border-bottom: 1px solid grey;

  &:last-child {
    border: 0;
    margin-bottom: 0;
  }
}

.edit {
  &-btn {
    color: $brandBlue;
    text-decoration: underline;
    border: 0;
    background: transparent;
  }
}
</style>
